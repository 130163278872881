export const columnsScheme = [
    {
        prop: "user_email",
        label: "Пользователи",
        columnStyles: {
            flex: 1.5,
        },
    },
    {
        prop: "suppliers_restriction",
        label: "Ограничение по операторам",
        columnStyles: {
            flex: 2,
        },
        isDropdownColumn: true,
        isSlot: true,
    },
    {
        prop: "cities_restriction",
        label: "Ограничение по городам",
        columnStyles: {
            flex: 2,
        },
        isDropdownColumn: true,
        isSlot: true,
    },
    {
        prop: "client",
        label: "Является клиентом",
        columnStyles: {
            flex: 1,
            'justify-content': 'center'
        },
        classes: ["text-center"],
        isSlot: true,
    },
    {
        prop: "agency",
        label: "Является агентством",
        columnStyles: {
            flex: 1,
            'justify-content': 'center'
        },
        isSlot: true,
        classes: ["text-center"],
    },
    {
        prop: "remove",
        label: "",
        columnStyles: {
            flex: 0.5,
            'justify-content': 'center'
        },
        isSlot: true,
        classes: ["text-center"],
    },
]

export const dropdownUserInfoOptionsScheme = [
    {
        name: "Редактировать",
        emit: "onChangeUserInfo",
    },
    {
        name: "Выйти",
        emit: "OnLogout",
    },
]

export const dropdownUserEntitiesOptionsScheme = [
    {
        name: "Редактировать",
        emit: "onChangeUserEntities",
    },
]

export const dropdownUserDealOptionsScheme = [
    {
        name: "Редактировать",
        emit: "onChangeUserDeal",
    },
]
