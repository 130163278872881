<template>
    <div class="profile-access">
        <div class="access__control">
            <BaseButton
                view="secondary"
                @click="isSetAccessModalVisible = true"
            >
                Предоставить права
            </BaseButton>
        </div>

        <div class="access-table">
            <AccessTable primaryKey="id" :columns="columns" :rows="rows">
                <template v-slot:suppliers_restriction="scopeData">
                    <BaseSelectMultipleV2
                        canSearch
                        isFullWidth
                        isTableMultiSelect
                        textBtn="Ок"
                        :isLoadingOptions="isLoading"
                        :value="scopeData.row.suppliers_restriction.value"
                        :options="scopeData.row.suppliers_restriction.items"
                        @check="onRestrictionsSelectCheck('suppliers_restriction', $event, scopeData.row)"
                    />
                </template>
                <template v-slot:cities_restriction="scopeData">
                    <BaseSelectMultipleV2
                        canSearch
                        isFullWidth
                        isTableMultiSelect
                        textBtn="Ок"
                        :isLoadingOptions="isLoading"
                        :value="scopeData.row.cities_restriction.value"
                        :options="scopeData.row.cities_restriction.items"
                        @check="onRestrictionsSelectCheck('cities_restriction',$event, scopeData.row)"
                    />
                </template>
                <template v-slot:client="scopeData">
                    <BaseCheckboxV2
                        :checked="scopeData.row.permissions.includes('client')"
                        @change="onPermissionsCheckboxChange('client', $event, scopeData.row)"
                    />
                </template>
                <template v-slot:agency="scopeData">
                    <BaseCheckboxV2
                        :checked="scopeData.row.permissions.includes('agency')"
                        @change="onPermissionsCheckboxChange('agency', $event, scopeData.row)"
                    />
                </template>
                <template v-slot:remove="scopeData">
                    <BaseButtonIcon
                        tooltip-text=""
                        icon-component="IconTrash"
                        class="icon-delete-gray"
                        @onBaseButtonIcon="removeUserAccess(scopeData.row)"
                    />
                </template>
            </AccessTable>
        </div>

        <UserAccessModal
            v-if="isSetAccessModalVisible"
            :accessError="accessError"
            :isLoading="isSettingAccess"
            @onCloseModal="isSetAccessModalVisible = false"
            @onApply="setAccess"
        />
    </div>
</template>

<script>
// import IconDeleteGray from "@/components/Icons/IconDeleteGray";
import BaseButton from "@/components/Base/BaseButton";
import BaseCheckboxV2 from "@/components/Base/BaseCheckboxV2";
import BaseButtonIcon from "@/components/Base/BaseButtonIcon";
import BaseSelectMultipleV2 from "@/components/Base/BaseSelectMultipleV2";
import UserAccessModal from "../components/MapV2/components/UserProfile/UserAccessModal";
import AccessTable from "../components/Table2/Table";
import ServiceUser from "@/services/ServicesAuth/ServiceUser";
import { columnsScheme } from "../schemes/SchemeUserProfile";

export default {
    name: "PageUserProfile",
    components: {
        BaseButton,
        AccessTable,
        // IconDeleteGray,
        BaseButtonIcon,
        BaseSelectMultipleV2,
        UserAccessModal,
        BaseCheckboxV2,
    },

    data() {
        return {
            isLoading: true,
            isSetAccessModalVisible: false,
            accessError: "",
            isSettingAccess: false,

            columns: columnsScheme,
            rows: [],
            suppliers: {},
            cities: {},
            isLoadingOptions: false
        };
    },

    async beforeCreate() {
        this.isLoading = true;

        // await ServiceUser.getAccessList((data) => console.log(data));

        await ServiceUser.getSuppliersList((list) => this.suppliers = list);

        await ServiceUser.getCitiesList((list) => this.cities = list);

        await ServiceUser.getAccessGivenList((data) => {
            if (data.users?.permissions) {
                data.users.permissions.forEach((user) =>
                    this.rows.push({
                        id: user.login,
                        user_email: user.login,
                        suppliers_restriction: { items: [], value: user.suppliers.map((item) => String(item)) },
                        cities_restriction: { items: [], value: user.cities.map((item) => String(item)) },
                        permissions: user.permissions,
                    })
                );

                this.rows = this.rows.map((row) => {
                    for (const key in this.cities) {
                        row.cities_restriction.items.push({
                            id: key,
                            value: this.cities[key],
                        });
                    }
                    for (const key in this.suppliers) {
                        row.suppliers_restriction.items.push({
                            id: key,
                            value: this.suppliers[key],
                        });
                    }
                    return row;
                });
            }
        });

        this.isLoading = false;
    },

    methods: {
        async removeUserAccess(currentRowData) {
            await ServiceUser.removeUserAccess(
                { login: currentRowData.user_email },
                (res) => {
                    if (res.success) {
                        this.rows = this.rows.filter(
                            (row) =>
                                row.user_email !== currentRowData.user_email
                        );
                    }
                }
            );
        },

        onPermissionsCheckboxChange(selectType, checked, currentRow) {
            let actualPermissions = currentRow.permissions;

            if (actualPermissions.includes(selectType) && !checked) {
                actualPermissions = actualPermissions.filter(
                    (item) => item !== selectType
                );
            } else if (!actualPermissions.includes(selectType) && checked) {
                actualPermissions.push(selectType);
            }

            if (!actualPermissions.length) {
                actualPermissions.push(
                    selectType === "agency" ? "client" : "agency"
                );
            }

            const reqBody = {
                login: currentRow.user_email,
                permissions: actualPermissions,
                cities: currentRow.cities_restriction.value,
                suppliers: currentRow.suppliers_restriction.value,
            };

            ServiceUser.addUserAccess(reqBody, () => {
                this.rows = this.rows.map((row) => {
                    if (row.user_email === currentRow.user_email) {
                        row.permissions = actualPermissions;
                    }
                    return row;
                });
            });
        },

        async setAccess(login) {
            this.isSettingAccess = true;

            await ServiceUser.addUserAccess({ login }, (res) => {
                this.isSettingAccess = false;

                if (!res.success) {
                    this.accessError = res.errors.login;
                    return;
                }

                const currentRow = this.rows.find((row) => row.user_email.toLowerCase() === login.toLowerCase())

                if (currentRow) {
                    this.accessError =
                        "Вы уже предоставили права данному пользователю. Редактировать права можно в таблице на экране";
                    return;
                }

                const cities_restriction = [];
                for (const key in this.cities) {
                    cities_restriction.push({ id: key, value: this.cities[key], });
                }

                const suppliers_restriction = [];
                for (const key in this.suppliers) {
                    suppliers_restriction.push({ id: key, value: this.suppliers[key], });
                }

                const reqBody = {
                    login,
                    suppliers: [""],
                    cities: [""],
                    permissions: Object.keys(res.permissions),
                };

                ServiceUser.addUserAccess(reqBody, () => {
                    this.rows.push({
                        id: login,
                        user_email: login,
                        suppliers_restriction: {
                            items: suppliers_restriction,
                            value: "",
                        },
                        cities_restriction: {
                            items: cities_restriction,
                            value: "",
                        },
                        permissions: Object.keys(res.permissions),
                    });

                    this.isSetAccessModalVisible = false;
                });
            });
        },

        onRestrictionsSelectCheck(selectType, value, currentRow) {
            const reqBody = {
                login: currentRow.user_email,
                permissions: currentRow.permissions,
                cities:
                    selectType === "cities_restriction"
                        ? value
                        : currentRow.cities_restriction.value,
                suppliers:
                    selectType === "suppliers_restriction"
                        ? value
                        : currentRow.suppliers_restriction.value,
            };

            ServiceUser.addUserAccess(reqBody, () => {
                this.rows = this.rows.map((row) => {
                    if (row.user_email === currentRow.user_email) {
                        row[selectType].value = value;
                    }

                    return row;
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.profile-access {
    position: relative;
    height: 100%;
}
.access-table {
    position: absolute;
    inset: 65px 0 0 0;

    padding: 30px;
    background: #fff;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.access__control {
    padding: 30px 30px 0;
    background: #fff;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.icon-delete-gray {
}
</style>
