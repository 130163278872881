<template>
  <base-modal @close="onCloseModal" class="user-access-modal">
    <template v-slot:modalBody>
      <div class="user-access-modal__body">
        <h3 class="user-access-modal__header">Предоставить права доступа</h3>

        <div class="user-access-modal__row">
          <p class="user-access-modal__text">Укажите e-mail зарегистрированного пользователя, которому хотите предоставить доступ</p>
          <base-text-input
            view="primary"
            placeholder="Введите e-mail"
            v-model.trim="userEmailUpdated"
          />
          <div v-if="accessError.length" class="user-access-modal__error">
            {{ accessError }}
          </div>
        </div>

        <div class="user-access-modal__actions">
          <base-button form="square" view="secondary" @click="onApply">
            Найти пользователя
          </base-button>
        </div>
      </div>

      <BasePreloaderGif v-if="isLoading" class="user-access-modal__preloader" />
    </template>
  </base-modal>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton";
import BasePreloaderGif from "@/components/Base/BasePreloaderGif.vue";
import BaseModal from "@/components/Base/BaseModal";
import BaseTextInput from "@/components/Base/BaseTextInput";

export default {
  components: {
    BaseModal,
    BaseButton,
    BasePreloaderGif,
    BaseTextInput,
  },
  props: {
    userEmail: {
      type: String,
      default: "",
    },
    accessError: {
      type: String,
      default: "",
    },
    isUserAgency: {
      type: Boolean,
      default: false,
    },
    isUserClient: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userEmailUpdated: "",
      isUserAgencyUpdated: false,
      isUserClientUpdated: false,
    };
  },
  mounted() {
    this.userEmailUpdated = "";
  },
  methods: {
    onCloseModal() {
      this.$emit("onCloseModal");
    },
    onApply() {
      this.$emit("onApply", this.userEmailUpdated);
    },
  },
};
</script>

    <style lang="scss">
.user-access-modal {
  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    /*width: 360px;*/
    min-height: max-content;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
  }

  &__preloader {
    position: absolute !important;

    & .container-fluid {
        margin-top: 30px;
    }
  }

  &__header {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #000;

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  &__row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 25px;
    }

    &._select {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  &__error {
    font-size: 12px;
    line-height: 16px;
    color: rgb(255, 75, 75);
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cancel {
    color: #c8cad1;

    &:hover,
    &:active {
      color: #333;
    }
  }
}
</style>
